import ContactCard from "../Cards/ContactCard/ContactCard";
import GoogleMap from "../Google Map/GoogleMap";
import useDynamicPageTitle from "../Reuseable Components/DynamicPageTitle/DynamicPageTitle";
import Footer from "../Reuseable Components/Footer/Footer";
import MainHeader from "../Reuseable Components/Main Header/MainHeader";

const ContactUsPage = () => {
  useDynamicPageTitle(
    "Contact Us - Sunrise Pharmacy - Your Local Orange Pharmacy"
  );
  return (
    <>
      <MainHeader />
      <br />
      <ContactCard />
      <GoogleMap />
      <Footer />
    </>
  );
};

export default ContactUsPage;
