import Coronavirus from "../CoronaVirusForm/VaccinesForm";
import useDynamicPageTitle from "../Reuseable Components/DynamicPageTitle/DynamicPageTitle";
import Footer from "../Reuseable Components/Footer/Footer";
import MainHeader from "../Reuseable Components/Main Header/MainHeader";

const CoronaVirusPage = () => {
  useDynamicPageTitle(
    "Vaccine Updates & Information - Sunrise Pharmacy - Your Local Orange Pharmacy"
  );

  return (
    <>
      <MainHeader />
      <Coronavirus />
      <Footer />
    </>
  );
};

export default CoronaVirusPage;
