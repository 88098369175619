// Data for Main Casrousel
export const slideStyles = [
  {
    backgroundImage:
      'url("../Assets/Home Page/Welocme to Sunrise Phasrmacy.jpg")',
    link: "/about-us",
    title: "Welcome to Sunrise Pharmacy",
    description: "Care Beyond Drugs",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Diabetes Care.jpeg")',
    link: "/diabetes-specialized-care-center",
    title: "Diabetes Care",
    description:
      "Allow us to assist you in comprehending and handling your diabetes",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Med Pre-Pours.jpg")',
    link: "/med-pre-pours",
    title: "Med Pre-Pours",
    description:
      "We provide multi-dose packaging that organizes your medications based on both date and time",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Medical Equipments.jpg")',
    link: "/durable-medical-equipment",
    title: "Durable Medical Equipment",
    description: "Available in stock and upon request can be ordered",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Health Screening.jpeg")',
    link: "/health-screenings",
    title: "Health Screenings",
    description:
      "The pharmacist is conducting a health screening for the patient",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Immunization.jpeg")',
    link: "/immunizations",
    title: "Immunizations",
    description: "We provide a range of vaccines to ensure your well-being",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Leader Products.jpg")',
    link: "/leader-products",
    title: "Leader Products",
    description:
      "We take pride in presenting LEADER™ high-quality products, featuring identical active ingredients as national brands but at a more affordable price",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Medication Adherence.jpg")',
    link: "/medication-adherence",
    title: "Medication Adherence",
    description:
      "Prevent medication non-adherence by allowing our pharmacists to assist you in effectively managing your medications",
  },
  {
    backgroundImage:
      'url("../Assets/Home Page/Medication Synchronization.jpeg")',
    link: "/medication-synchronization",
    title: "Medication Synchronization",
    description:
      "Ensure you collect all your medications on a specific day each month",
  },
  {
    backgroundImage:
      'url("../Assets/Home Page/Medication Therapy Management.jpg")',
    link: "/medication-therapy-management",
    title: "Medication Therapy Management",
    description:
      "Assisting you in overseeing both your medications and health conditions is within our capabilities",
  },
  {
    backgroundImage:
      'url("../Assets/Home Page/Nutrition Depletion Counseling.jpg")',
    link: "/nutrient-depletion-counseling",
    title: "Nutrition Depletion Counseling",
    description:
      "Our team is skilled in recognizing drug-induced nutrient depletion and offers counseling on medication to assist",
  },
  {
    backgroundImage: 'url("../Assets/Home Page/Free Delivery.jpeg")',
    link: "/home-delivery",
    title: "Free Delivery",
    description: "Allow us to come to your location",
  },
];

// Data for Services Page Row 1
export const servicesData1 = [
  {
    imgSrc: "../Assets/Services/Diabetes Care.jpg",
    imgLink: "/diabetes-specialized-care-center",
    btnName: "Diabetes Specialized Care",
    content:
      "We can assist you in comprehending and handling your diabetes through specialized monitoring, medications, and recommendations.",
  },
  {
    imgSrc: "../Assets/Services/Med Pre-Pours.jpg",
    imgLink: "/med-pre-pours",
    btnName: "Med Pre-Pours",
    content:
      "We provide med pre-poured medication packaging that organizes your medications based on both date and time.",
  },
  {
    imgSrc: "../Assets/Home Page/Durable Medical Equipment.jpeg",
    imgLink: "/durable-medical-equipment",
    btnName: "Medical Equipment",
    content:
      "We stock an extensive range of medical equipment, encompassing ambulatory aids, nebulizers, and bathroom essentials.",
  },
  {
    imgSrc: "../Assets/Services/Health Screening.jpg",
    imgLink: "/health-screenings",
    btnName: "Health Screening",
    content:
      "We provide on-site health screenings, giving you the opportunity to gather health information without the need for a visit to the doctor.",
  },
];

// Data for Services Page Row 2
export const servicesData2 = [
  {
    imgSrc: "../Assets/Services/Immunixations.jpg",
    imgLink: "/immunizations",
    btnName: "Immunizations",
    content:
      "Explore our range of vaccines designed to support your well-being. Reach out to us for further details on our immunization options.",
  },
  {
    imgSrc: "../Assets/Services/Leader Products.jpg",
    imgLink: "/leader-products",
    btnName: "Leader Products",
    content:
      "We take pride in providing LEADER™ high-quality products that feature identical active ingredients as national brands but come at a more affordable price.",
  },
  {
    imgSrc: "../Assets/Services/Medication Adherence.jpg",
    imgLink: "/medication-adherence",
    btnName: "Medication Adherence",
    content:
      "Prevent medication non-adherence by allowing our pharmacists to assist you in enhancing your medication management.",
  },
  {
    imgSrc: "../Assets/Services/Medication Synchronization.jpg",
    imgLink: "/medication-synchronization",
    btnName: "Medication Synchronization",
    content:
      "Synchronize your prescriptions with our pharmacy and collect all your medications on a consistent day each month.",
  },
];

// Data for Services Page Row 3
export const servicesData3 = [
  {
    imgSrc: "../Assets/Services/Medication Management.jpg",
    imgLink: "/medication-therapy-management",
    btnName: "Medication Management",
    content:
      "Allow our pharmacy team to assist you in effectively managing the medications and health conditions of you and your family.",
  },
  {
    imgSrc: "../Assets/Services/Nutrient Depletion Counseling.jpg",
    imgLink: "/nutrient-depletion-counseling",
    btnName: "Nutrient Depletion Counseling",
    content:
      "Our team is skilled in recognizing nutrient depletion caused by medications and offers counseling to provide assistance.",
  },
  {
    imgSrc: "../Assets/Services/Home Delivery.png",
    imgLink: "/home-delivery",
    btnName: "Home Delivery",
    content:
      "Enjoy complimentary delivery within the nearby Orange area. Reach out to us for more information or to arrange your free delivery.",
  },
];
