import { Link } from "react-router-dom";
import "./StickyHeader.css";

const StickyHeader = () => {
  return (
    <strong className="header">
      Interest in getting vaccinated?&nbsp;
      <Link to="/vaccines" className="link">
        Get on the waitlist
      </Link>
    </strong>
  );
};

export default StickyHeader;