import StickyHeader from "../../StickyHeader/StickyHeader";
import Address from "../Address/Address";
import Navbar from "../NavBar/NavBar";

const MainHeader = () => {
    return (
        <>
            <StickyHeader />
            <Address />
            <Navbar />
        </>
    );
}
 
export default MainHeader;