import useDynamicPageTitle from "../Reuseable Components/DynamicPageTitle/DynamicPageTitle";
import Footer from "../Reuseable Components/Footer/Footer";
import TransferPrescription from "../Reuseable Components/TransferPrescription/TransferPrescription";
import MainHeader from "../Reuseable Components/Main Header/MainHeader";

const TransferAPrescription = () => {
  useDynamicPageTitle(
    "New Patient Form - Sunrise Pharmacy - Your Local Orange Pharmacy"
  );

  return (
    <>
      <MainHeader />
      <TransferPrescription />
      <Footer />
    </>
  );
};

export default TransferAPrescription;
